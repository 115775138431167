.custom-radio-group{
    &>input{
        display: none;

        &:checked + label{
            border-color: #000;
            background-color: #F2F2F2;

            &>.custom-radio::after{
                content: "";
                width: 14px;
                height: 14px;
                border-radius: 50%;
                background-color: #000;
            }

            & .details-title{
                color: #7B7B7B;
            }
        }
    }

    &>label{
        display: flex;
        align-items: flex-start;
        gap: 12px;
        border: 1px solid #F2F2F2;
        border-radius: 8px;
        padding: 16px;
        cursor: pointer;


        &>.custom-radio{
            flex-shrink: 0;
            display: grid;
            place-content: center;
            width: 24px;
            height: 24px;
            border: 1px solid #000;
            border-radius: 50%;
        }

        &>.label-content{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 8px;

            &>div:first-of-type{
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            &>div:last-of-type{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                align-items: center;
                gap: 12px;
            }
        }

        & .details-title{
            font-size: 14px;
            color: #B1B1B1;
        }
    }
}